.main-heading {
    font-size: 35px;
    font-family: 'Silkscreen';
    color: var(--dark-black);
    margin-bottom: 0;
    padding-bottom: 20px;

    @media screen and (max-width : 768px) {
        font-size: 20px;
    }

    &.text-mb-center {
        @media screen and (max-width:768px) {
            text-align: center;
        }
    }

    &.font-increase {
        font-size: 50px !important;

        @media screen and (max-width: 992px) {
            font-size: 35px;
        }
    }
}

.text-heading {
    font-size: 18px;
    color: var(--dark-black);
    margin-bottom: 0;

    @media screen and (max-width : 768px) {
        font-size: 14px;
    }

    &.size-increase {
        @media screen and (max-width : 768px) {
            font-size: 18px;
        }
    }
}

.number-mobile-error {
    position: relative;
}


.error {
    @extend.text-heading ;
    color: red;
    // position: absolute;
    // bottom: 0;
    // right: 5px;
}