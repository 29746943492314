.last-linking {
    text-align: center;
    padding: 100px 0;

    .footer-logo {
        padding: 20px 0;

        img {
            width: 90px;
        }
    }

    .social-icon {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;

        .fa {
            font-size: 30px;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 15px;
        color: var(--color);
    }
}