:root {
    --theme-color: hsl(0, 0%, 8%);
    --dark-black: #dd9a42;
    --link-color: rgb(212, 212, 212);
    --secondary: rgb(212, 212, 212);
    --input-color: rgba(255, 255, 255, 0.5);
    --light-gray: #403f4c;
    --white-color: #fff;
    --card-color: hsl(0, 0%, 15%);
    --card-dark: hsl(0, 0%, 11%);
    --blue-color: #005CA3;
    --light-blue: #241D34;
    --gray-color-new-text: #424E57;
    --blue-dark-color: #7D33C2;
}

$theme-color: var(--theme-color);
$dark-black: var(--dark-black);
$link-color: var(--link-color);
$input-color: var(--input-color);
$light-gray-color: var(--light-gray);
$white-color: var(--white-color);
$card-color: var(--card-color);
$card-dark: var(--card-dark);
$color-blue: var(--blue-color);
$color-light-blue: var(--light-blue);
$color-text-light: var(--gray-color-new-text);
$blue-color-dar: var(--blue-dark-color);