.navbar {
    padding: 24px 61px;
    background-color: transparent !important;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);

    @media screen and (max-width : 768px) {
        padding: 24px 20px 24px 29px;
    }

    @media screen and (max-width : 568px) {
        padding: 24px 10px;
    }

    .container {
        max-width: 1240px;
        width: 100%;
    }

    .Logo {
        display: flex;
        align-items: center;
        font-family: 'Alice';

        .logo-icon {

            img {
                width: 210px;
                height: 50px;
                margin-right: 10px;

            }
        }

        span {
            font-size: 23px;
            color: var(--dark-black);

            letter-spacing: 1px;

        }
    }

    .navbar-nav {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width :992px) {
            text-align: center;
        }

        .nav-item {
            padding: 0 3px;



            @media screen and (max-width : 992px) {
                padding: 20px 0;

            }

            .nav-link {
                color: var(--dark-black);
                letter-spacing: 1px;
                font-size: 16px;
                display: inline;

                &:hover {
                    text-decoration: underline;
                    color: var(--dark-black);
                }

                &.active {
                    color: var(--dark-white);
                    text-decoration: underline;
                }
            }
        }


    }
}

.navbar-light .navbar-toggler {
    border-color: transparent;
    border: 0px solid rgba(128, 128, 128, 0.226);


    @media screen and (max-width : 992px) {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 0px rgba(128, 128, 128, 0.226);
        background-color: transparent;
    }

    img {
        width: 20px;
    }

}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0px 0px 0px rgba(128, 128, 128, 0.226);
}