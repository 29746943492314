custom-button {
    padding: 10px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    outline: 0;
    border: 0;
    margin: 5px 0;
    box-shadow: none;
    font-size: 15px;
    letter-spacing: 1px;
    cursor: pointer;

    @media screen and (max-width : 360px) {
        font-size: 14px;
    }
}

.theme-button {
    @extend custom-button;
    background-color: var(--dark-black);
    color: var(--theme-color);
    padding: 10px 35px;

    @media screen and (max-width : 992px) {
        padding: 10px 22px;

    }

    &:hover {
        color: var(--theme-color);
        background: var(--secondary);
    }

    &.active-btn {
        color: var(--theme-color);
        background: var(--secondary);
    }


    &.pick-button {
        background-color: #e84855;
        color: var(--link-color);
    }

    &.gray-button {
        background-color: var(--light-gray);
        color: var(--link-color);
    }

    &.black-button {
        padding: 15px 70px;
        font-weight: 600;
        margin-bottom: 0;
    }

    &.disable-button {
        background-color: var(--light-gray);
        color: var(--link-color);
        pointer-events: none;

    }



}

.plan-vip {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    button {
        width: 100%;
        padding: 10px;
        letter-spacing: 0;

        @media screen and (max-width : 992px) {
            padding: 10px 7px;
        }
    }

    a {
        width: 100%;
        padding: 10px;
        letter-spacing: 0;

        @media screen and (max-width : 992px) {
            padding: 10px 7px;
        }
    }
}