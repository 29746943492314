.affiliate-body {
    background-color: var(--white-color);
    font-weight: 500px;
    font-family: sans-serif;
    color: var(--theme-color) !important;
}

.bg-inactive-button {
    --tw-bg-opacity: 1;
    background-color: rgb(218 215 225/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(164 157 180/var(--tw-text-opacity));
}

.hover-disable {
    cursor: not-allowed;

    &:hover {
        cursor: not-allowed;
    }
}

.bg-color-click {
    background-color: var(--blue-color);
    color: var(--white-color);
}

.header-top {
    width: 100%;
    height: 100px;
    background-color: var(--white-color);
    box-shadow: 0 2px 11px 1px #d5d5d5;
    position: fixed;
    top: 0;
    z-index: 2;

    @media screen and (max-width : 950px) {
        height: 80px;
        padding: 0 48px;
    }

    @media screen and (max-width : 568px) {

        padding: 0 1rem;
    }

    .space-con {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        @media screen and (min-width : 960px) {
            max-width: 712px;
            text-align: center;
            margin: 0 auto;
        }

        h1 {
            font-size: 28px;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 1;

            @media screen and (max-width : 768px) {
                font-size: 1.3rem;
            }
        }

        .choose-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid var(--blue-color);
                padding: 15px 29px;
                border-radius: 10px;
                color: var(--blue-color);
                text-decoration: none;

                @media screen and (max-width : 568px) {
                    padding: 10px 20px;
                }

                &:hover {
                    --tw-bg-opacity: 1;
                    background-color: rgb(230 240 248/var(--tw-bg-opacity));
                }

                &.bg-blue {
                    background-color: var(--blue-color);
                    color: var(--white-color);
                }
            }

        }

    }
}

.color-blue {
    color: var(--blue-color)
}

.color-black {
    color: var(--theme-color);
}

.color-gray {
    color: var(--light-gray);
}

.color-light-gray {
    --tw-text-opacity: 1;
    color: rgb(66 78 87/var(--tw-text-opacity));
}

.f-s {
    font-size: 12px;
}

.font-size-in {
    font-size: 14px;
    line-height: 23px;
}

.heading-all {
    font-size: 25px;
    margin-bottom: 0;
    font-weight: 700;
    color: var(--theme-color);
    padding-bottom: 15px;

    @media screen and (max-width : 568px) {
        font-size: 22px;
    }
}


.sub-heading-affiliate {
    @extend .heading-all;
    font-weight: 500;
}

.f-w-b {
    font-weight: 600;
}

.text-sm {
    font-size: 16px;
    font-weight: 600;
    color: var(--blue-color);
    cursor: pointer;

    &.f-c {
        font-size: 13px;
        color: var(--theme-color);
    }

    &.f-c-b {
        font-size: 13px;
        color: var(--blue-color);
    }
}

.fb {
    font-weight: 700;
}


.tickets-vip {
    padding-top: 146px;
    padding-bottom: 4rem;

    @media screen and (min-width : 768px) {
        max-width: 1012px;
        width: 100%;
        margin: 0 auto;

    }

    @media screen and (max-width : 768px) {
        padding: 100px 20px;
    }

    &.pd-set {
        @media screen and (max-width : 578px) {
            padding: 100px 10px;
        }
    }

    &.thank-page {
        @media screen and (max-width : 768px) {
            height: calc(100vh - 100px);
            display: flex;
            align-items: center;
            padding: 100px 0 0;
        }
    }

    .vip-conscious {
        // max-width: 300px;
        width: 100%;
        // max-height: 180px;
        padding: 40px 0px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        margin: 0 auto 10px;

        @media screen and (max-width : 668px) {
            width: 100%;
            padding-bottom: 20px;
            gap: 10px;
        }

        img {
            width: 200px;
            height: 200px;
            object-fit: cover;

            @media screen and (max-width : 690px) {
                width: 140px;
                display: block;
                margin: 0px 0 0px;
                // height: 135px;
            }

        }

    }

    .vip-text {
        margin-bottom: 40px;
        padding-left: 0;

        @media screen and (max-width : 768px) {
            padding: 10px 0;
        }


        p {
            font-size: 15px;
            color: var(--light-gray);
            line-height: 26px;
            margin-bottom: 0;
            padding-top: 20px;
            // padding-left: 12px;
        }
    }

    .compensated {
        --tw-border-opacity: 1;
        border-color: rgb(18 203 91/var(--tw-border-opacity));
        border: 1px solid rgb(18 203 91/var(--tw-border-opacity));
        border-radius: 5px;
        --tw-bg-opacity: 1;
        background-color: rgb(231 249 238/var(--tw-bg-opacity));
        padding: 24px 40px;
        margin-bottom: 40px;

        @media screen and (max-width : 568px) {
            padding: 24px 20px;
        }

        ul {
            padding-left: 50px;

            @media screen and (max-width : 568px) {
                padding-left: 20px;
            }

            li {
                font-size: 16px;
                font-weight: 400;
                color: var(--light-gray);
            }

        }

        ul ul {
            padding-top: 20px;
        }

        .img-product {
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            // flex-wrap: wrap;
            gap: 10px;
            padding-top: 50px;
            padding-bottom: 35px;

            @media screen and (max-width : 768px) {
                padding-bottom: 20px;
                padding-top: 10px;
            }

            a {
                width: 260px;
                height: 150px;
                object-fit: cover;
                text-align: center;

                @media screen and (max-width : 568px) {
                    width: 100%;
                    height: 120px;
                }


                img {
                    width: 100%;
                    margin-bottom: 0px;
                    object-fit: cover;
                    height: 100%;

                    @media screen and (max-width : 768px) {
                        margin-bottom: 10px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .requirements {
        padding: 0;

        ul {
            padding-left: 15px;
            // padding-top: 20px;
            --tw-text-opacity: 1;
            color: rgb(66 78 87/var(--tw-text-opacity));

            li {
                padding-bottom: 14px;
                font-size: 14px;
            }
        }

        ul ul {
            padding-left: 30px;

            @media screen and (max-width : 568px) {
                padding-left: 20px;
            }
        }
    }

}

.capture-text {
    font-size: 15px;
    color: var(--gray-color-new-text);
    line-height: 24px;
}

.new-input {
    background-color: #dcdada4d !important;
    border-color: var(--light-gray) !important;
    padding: 8px 12px !important;
    border-radius: 10px !important;
    color: var(--gray-color-new-text) !important;

    &:focus {
        border-color: var(--blue-dark-color) !important;
        outline: 0px solid var(--blue-dark-color) !important;
    }

    &::placeholder {
        color: var(--light-gray) !important;
        font-size: 15px !important;
        font-weight: 400 !important;
    }
}

.form-select:focus {
    box-shadow: none;
}

.form-img {
    background-image: url('../../../public/assets/images/down.png') !important;
}

.opportunity-form {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
}

.modal-from-fill {
    padding: 40px;

    h4 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 0;
        padding-bottom: 20px;
        color: var(--theme-color);
    }

    button {
        width: 100%;
        box-shadow: none;
        outline: 0;
        padding: 11px 0;
        font-size: 16px;
        border: 0;
        border-radius: 9px;
        font-weight: 700;
        margin-bottom: 20px;
    }
}

.error-form-file {
    display: block;
    text-align: center;
    color: red;
    margin-bottom: 0;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 50px;


}

.submit-button {
    display: block;
    text-align: right;


    .application-button {
        padding: 15px 23px;
        border: 0;
        outline: 0;
        box-shadow: none;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 600;

        @media screen and (max-width : 768px) {
            width: 100%;
        }
    }
}

.text-form {
    font-size: 15px;
    font-weight: 600;
    color: var(--theme-color);
    margin-bottom: 0;
    padding-bottom: 20px;
    cursor: pointer;

    &.color-change {
        color: var(--blue-color);
    }
}

.out-field {
    border: 1px solid var(--light-gray);
    padding: 0px 0 0 12px !important;
    border-radius: 10px !important;
    margin-bottom: 10px;

    .PhoneInputCountry {
        height: 0 !important;
        padding: 17px 0 !important;
    }

    .PhoneInput {
        height: 39px !important;
    }

    .phone-number {
        input {
            border: 0px;
            padding-left: 20px;
            padding: 13px 20px;
            height: 100%;
            background-color: rgba(220, 218, 218, 0.3019607843);
            color: var(--theme-color);

            &:focus {
                border: 1px solid var(--blue-dark-color);
                outline: 0;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }

}

.input-heading {
    font-size: 14px;
    color: var(--theme-color);
    padding: 10px 0;
    font-weight: 700;
}

.pass-img {
    max-width: 405px;
    width: 100%;
    margin-bottom: 20px;


    img {
        width: 100%;

    }
}

.check-box-click-img {

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0px;
    gap: 20px;

    @media screen and (max-width : 768px) {
        flex-wrap: wrap;
        margin-left: 0px;
        justify-content: flex-start;
        gap: 0 20px;
    }
}



.check-box-items {
    display: flex;
    align-items: center;

    .form-check-input:focus {
        box-shadow: none;
    }

    .form-check-input {
        padding: 8px !important;
        margin-top: 2px !important;
    }

    .form-check-input:checked {
        background-color: var(--blue-dark-color);
        border-color: var(--blue-dark-color);
    }

    img {
        width: 200px;
        margin-top: 40px;
        margin-bottom: 40px;

        @media screen and (max-width:678px) {
            width: 100%;
        }
    }

    p {
        text-align: center;
        font-size: 20px;
        margin-bottom: 0;
    }

}

.agreement {
    --tw-bg-opacity: 1;
    background-color: rgb(244 235 252/var(--tw-bg-opacity));
    border: 1px solid rgb(244 235 252/var(--tw-bg-opacity));
    border-radius: 5px;
    --tw-border-opacity: 1;
    border-color: rgb(147 61 228/var(--tw-border-opacity));
    padding: 24px 40px;
    margin-top: 20px;

    @media screen and (max-width : 568px) {
        padding: 24px 20px;
    }
}

.agreement-text {
    font-size: 13px;
    color: var(--theme-color);

    .a-m-f-w {
        font-weight: 700;
        color: #491E71;
    }
}

.check-agger {
    margin-top: 20px;
    padding-bottom: 10px;

    .form-check-input {
        border: 1px solid var(--blue-dark-color);
        padding: 8px;
    }

    .form-check-input:focus {
        box-shadow: none;
    }

    .form-check-input:checked {
        background-color: var(--blue-dark-color);
        border-color: var(--blue-dark-color);
    }

    label {
        font-size: 12px;
        color: var(--theme-color);
        padding-left: 5px;

        a {
            color: var(--blue-color);
            font-weight: 700;
        }
    }
}

.rmsc {
    .dropdown-container {
        border-radius: 10px !important;
        border-color: var(--light-gray) !important;
    }

    .dropdown-content {
        padding: 3px 0px !important;
        border-color: var(--light-gray) !important;
    }

    .panel-content {
        padding: 5px 20px !important;
        border-radius: 0 !important;
        margin-top: 5px;
    }

    .options {



        li {
            .select-item {
                &:hover {
                    --tw-bg-opacity: 1 !important;
                    background-color: rgb(244 235 252/var(--tw-bg-opacity)) !important;
                }

                background-color: var(--white-color) !important;

            }

            input[type="checkbox"] {
                color: var(--white-color) !important;
            }

            input[type='checkbox'] {
                accent-color: var(--blue-dark-color) !important;
            }

        }
    }


}




.opt-generate {
    display: flex;
    gap: 10px;
    justify-content: center;
    padding-bottom: 30px;

    input {
        width: 40px;
        height: 50px;
        border: 2px solid var(--light-gray);
        border-radius: 4px !important;
        font-size: 30px;
        font-weight: 700;
        color: var(--light-gray);
        margin-bottom: 0;
        padding: 0 !important;
        text-align: center;
        padding-bottom: 0px !important;


    }

    ::placeholder {
        font-size: 30px !important;
        font-weight: 700 !important;
        color: rgba(175, 175, 175, 0.377) !important;
    }

}

.create-opp {
    .check-box-items {
        padding-top: 7px;
        padding-bottom: 7px;
        display: flex;
        width: 100%;
        align-items: flex-start;


        label {
            padding-left: 10px;
        }
    }
}






.overlay-affiliate {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #00000085;
        z-index: 999;
    }
}

.thank-image {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
}

.contact {
    margin-bottom: 40px;
}

.artist-cr {
    height: 127px;
    width: 100%;
    object-fit: cover;
}

.srv-validation-message {
    color: red;
    font-size: small;
}

.need-heading {
    font-size: 16px;
    margin-bottom: 0;
    padding: 10px 0 20px;
    font-weight: 600;
    color: var(--theme-color);
}

// .img-dj-set {
//     width: 186px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-wrap: wrap;
//     row-gap: 5px;
//     column-gap: 2px;


//     @media screen and (max-width : 568px) {
//         width: 120px;
//     }

//     .asters {
//         width: 90px;
//         height: 90px;
//         background: #444;
//         margin: 0 auto;
//         overflow: hidden;
//         border-radius: 50px;
//         display: flex;
//         justify-content: center;
//         align-items: center;


//         @media screen and (max-width : 568px) {
//             width: 50px;
//             height: 50px;
//         }

//         img {
//             max-width: auto;
//             max-height: 100%;
//             width: auto;
//             height: 100%;
//         }
//     }

//     .plus-more {
//         display: block;
//         width: 100%;
//         box-shadow: none;
//         border: 0;
//         background-color: transparent;
//         padding-top: 0px;

//         @media screen and (max-width: 768px) {
//             padding-top: 0px;
//         }
//     }

// }

.note {
    position: relative;
    padding-left: 70px;
    margin-left: 21px;
    font-size: 14px;

    &::before {
        content: 'Note :';
        position: absolute;
        left: 0px;
        top: 0;
        font-size: 14px;
        color: var(--secondary);
        font-family: "SpaceMono";
        color: rgb(138, 61, 61);
    }


}

.select-pass {
    // max-width: 300px;
    width: 100%;
    margin: 30px auto;
    grid-column-gap: 20px;
    min-height: 220px;


    .card {
        background-color: var(--card-dark);
        border: 0px;
        padding: 0;

        .card-body {
            display: flex;
            flex-direction: column;
            text-align: center;
            // padding: 0px 30px;

            h4 {
                background-color: var(--card-color);

                padding: 12px;
                font-size: 16px;
                color: var(--secondary);
                font-weight: 600;
            }

            .form-select {
                margin: 0px 0 20px;
            }

            button {
                margin-top: auto;

            }
        }

    }
}