form {
    width: 100%;

    .stripe-input,
    input,
    select {
        // width: 100%;
        background-color: var(--theme-color);
        border: 1px solid rgb(255 255 255 / 50%);
        border-radius: 0;
        font-size: 16px;
        padding: 10px;
        color: var(--dark-black);
        outline: 0;

        &::placeholder {
            color: rgba(255 255 255/50%);
        }

        &:active,
        &:focus {
            outline: 1px solid;
        }
    }

    .PhoneInputCountry {
        border: 1px solid rgba(255, 255, 255, 0.5);
        height: 46px;
        padding: 10px;
        font-size: 16px;
        margin-right: 0;
        border-right: 0 !important;
    }
}

.input-width {
    width: 100%;
}