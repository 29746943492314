@font-face {
    font-family: 'SpaceMono';
    src: url('../../../public/assets/fonts/SpaceMono-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'SpaceMono';
    src: url('../../../public/assets/fonts/SpaceMono-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Silkscreen';
    src: url('../../../public/assets/fonts/Silkscreen-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Alice';
    src: url('../../../public/assets/fonts/Alice.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../../public/assets/fonts/Roboto-Regular.ttf');
    font-weight: 500;
}