@import "./components/index.scss";
@import "./Outercss/index.scss";

body {
    background-color: var(--theme-color);
    font-family: 'SpaceMono';
    font-weight: 400;
    color: var(--secondary);
}

.book-vip {
    position: relative;
    overflow: hidden;

    &.border-add {
        border-radius: 50px;
        outline: 3px solid var(--dark-black);
    }

    &.overlay-sold-out-img {
        &::before {
            content: 'Sold Out';
            font-size: 24px;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #000000c4;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    &.overlay-img {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #000000c4;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    img {
        // position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 50.4px;
    }
}

.circle-red {
    fill: #d82828 !important;
}

.circle-red,
.circle-blue {
    stroke: #fff;
    stroke-miterlimit: 10;
}

.circle-blue {
    fill: #3d32f0;
}

a {
    text-decoration: none;
}

.hero {
    padding: 80px 90px 40px;

    @media screen and (max-width : 1292px) {
        padding: 40px 10px;
    }

    .passes {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        p {
            @extend .text-heading;
        }

        &:hover {
            background-color: var(--white-color);

            p {
                color: var(--theme-color);
            }
        }
    }
}

.price-text {
    margin-bottom: 5px;
    font-size: 22px;
    margin-top: 0;
    color: var(--white-color);
    margin-bottom: 20px;

    &.special-price-text {
        text-decoration: line-through;
        font-size: 18px;
        color: #8f959b !important;
    }
}

// home page css start
.join-now {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    margin-top: 110px;
    text-align: center;
    color: var(--dark-black);


    img {
        @media screen and (max-width :992px) {
            max-width: 50px;
            width: 100%;
        }
    }

    .join-text {
        font-size: 50px;
        font-family: 'Silkscreen';
        color: var(--dark-black);
        margin-bottom: 0;
        padding: 15px 0;

        @media screen and (max-width : 992px) {
            font-size: 30px;
        }
    }
}

.hero-text {

    padding-left: 40px;
    color: var(--dark-black);
    margin-bottom: 50px;
    padding-right: 50px;

    @media screen and (max-width : 992px) {

        padding-right: 0;
        padding-left: 0;
    }

    .theme-button {
        margin-top: 30px;
    }
}

.hero-images {
    margin-bottom: 50px;
    max-width: 540px;
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        border-radius: 10%;
        height: 100%;
        object-fit: cover;
    }
}

.cross-logo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    grid-gap: 20px;
    padding: 0 100px;

    @media screen and (max-width : 992px) {
        padding: 0 0px;
    }

    img {
        max-width: 200px;
        width: 100%;

        @media screen and (max-width : 500px) {
            max-width: 150px;
        }
    }
}

.visit-accordion {
    @media screen and (max-width : 768px) {
        padding: 0 10px;
    }
}

.weekend-day {

    @media screen and (max-width : 768px) {
        padding: 0 10px;
    }

    .weekend {
        background-image: url('../../public/assets/images/section_03.jpg');
        padding: 180px 100px;
        background-position: 10% center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        border-radius: 40px;
        margin-bottom: 20px;

        @media screen and (max-width : 992px) {
            padding: 100px 5px;
        }

        .event {
            background-color: var(--white-color);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 10px;
            margin-bottom: 5px;

            p {
                @extend .text-heading;
                color: var(--theme-color);
            }

            &:hover {
                background-color: var(--theme-color);

                p {
                    color: var(--dark-black);
                }
            }
        }
    }

    .music {
        margin-bottom: 60px;

        @media screen and (max-width:992px) {
            margin-bottom: 30px;
        }

        img {
            border-radius: 6%;
        }

        &.music-img {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            img {
                height: 450px;
                width: 100%;

                @media screen and (max-width : 768px) {
                    height: 100%;
                }
            }
        }
    }

    .opening {

        margin-bottom: 0;
        // margin-top: 180px;
        // margin-bottom: 60px;

        // @media screen and (max-width : 992px) {
        //     margin-top: 30px;
        //     text-align: center;
        //     margin-bottom: 20px;
        // }
    }
}

.expression {
    padding: 90px 0 60px;

    @media screen and (max-width : 768px) {
        padding: 50px 10px 10px;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            padding-bottom: 20px;
            font-size: 18px;
            color: var(--link-color);
        }
    }
}

//  home page css end 

// plan visit css start
.plan-accordion {
    padding: 70px 90px 0;

    @media screen and (max-width : 992px) {
        padding: 30px 0px 0;
    }

    &__item {
        background-color: transparent;
        border: 1px solid transparent;
        padding-bottom: 0;

    }

    &__header {
        margin-bottom: 0
    }

    &__body {
        // padding: 0 200px 0 0;

        @media screen and (max-width : 992px) {
            padding-right: 0;

        }
    }

    &__listing-plan {
        list-style: none;
        padding: 0;


        li {
            padding: 10px 0;
            font-size: 14px;
            color: var(--dark-black);
        }
    }

    &__header {
        .accordion-button {
            background-color: transparent;
            box-shadow: none;
            border-top: 1px solid var(--dark-black);
            border-radius: 0;
            font-size: 17px;
            color: var(--dark-black);
            padding: 10px 20px 10px 0;

        }
    }

    &__button:not(.collapsed)::after {
        background-image: url(../../public/assets/images/down.png);
        transform: rotate(-180deg);
    }

    &__button:not(.collapsed) {
        color: var(--secondary);
    }

    &__button::after {
        background-image: url('../../public/assets/images/angle-down-white.svg');
        background-size: 15px;
        transform: translateX(5px);

    }

    button:not(.collapsed)::after {
        background-image: url('../../public/assets/images/angle-down-white.svg') !important;
        background-size: 15px;

    }

    .event-user {
        @media screen and (max-width:992px) {
            text-align: center;
            // font-size: 20px;

        }
    }


}

.plan-text {
    padding-right: 100px;

    @media screen and (max-width : 992px) {
        padding-right: 0px;
        // text-align: center;
    }
}

.closing {
    font-family: 'Silkscreen';
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--secondary);

    @media screen and (max-width : 992px) {
        text-align: center;
        margin-top: 20px;
        font-size: 14px;
    }
}

// plan visit css end 


// event passes css start 
.music-cover {
    h3 {
        @media screen and (max-width : 992px) {
            padding-bottom: 0;
        }
    }
}

.event-pass {
    padding: 0px 70px;

    @media screen and (max-width:992px) {
        padding: 0px 0;
    }

    &.event-new {
        display: flex;
        flex-direction: column;

        .change {
            margin-left: auto;
            padding-right: 25px;
        }
    }

    .card-items {
        height: 100%;
        background-color: var(--card-color);

        h3 {
            background-color: hsl(0, 0%, 20%);
            padding: 16px 0;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 6px;
            text-align: center;
            color: var(--secondary);
            margin-bottom: 0;
            text-transform: uppercase;

            @media screen and (max-width : 768px) {

                padding: 16px 10px;
                letter-spacing: 3px;
            }
        }

        .small {
            padding: 0px 20px;
        }


        .number-list {
            background-color: var(--card-color);
            text-align: center;
            padding: 40px 0px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            &.number-height {
                height: 100%;
            }

            &.number-custom {
                padding: 40px 30px 20px;

                @media screen and (max-width : 768px) {
                    padding: 40px 10px;
                }

            }

            @media screen and (max-width: 992px) {
                margin-bottom: 20px;
            }

            .list {
                display: flex;
                justify-content: center;
                gap: 10px;
                margin-bottom: 0px;
                padding: 0 50px 0 50px;
                flex-direction: column;

                @media screen and (max-width : 768px) {
                    padding: 0 20px;
                }

                &.card-number {
                    display: flex;
                    gap: 15px;
                    justify-content: flex-start;
                    text-align: left;
                    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
                    padding-bottom: 20px;
                    margin-bottom: 20px;


                }

                .month {
                    h4 {
                        font-size: 17px;
                        font-weight: 500;
                        color: var(--dark-black);
                        margin-bottom: 0;
                        padding-bottom: 10px;
                    }

                    .pb-set {
                        padding-bottom: 5px;
                    }
                }
            }

            .date-select-user-pro {
                padding: 10px 40px 0;

                @media screen and (max-width : 768px) {
                    padding: 10px 10px 0;
                }


                label {
                    text-align: center !important;
                    font-size: 12px;
                    display: block;
                    padding-bottom: 5px;
                    color: var(--input-color);
                }

                select {
                    width: 100%;
                    max-width: 230px;
                    margin: 0 auto;
                }


            }
        }

        &.dark {
            height: 100%;
            background-color: var(--card-dark);

            h3 {
                background-color: var(--card-color);
            }

            .number-list {
                background-color: var(--card-dark);
                display: flex;
                justify-content: space-between;
                flex-direction: column;

            }
        }
    }
}

.coming-soon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // padding-top: 50px;
    min-height: calc(100vh - 109px - 410px);
}

.event-text {
    max-width: 700px;
    width: 100%;
    margin: 60px auto 0;
    text-align: center;
    padding: 0 15px;
}

// event passes css end

// Lineup css start 
.artists {
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    column-gap: 50px;
    flex-wrap: wrap;

    @media screen and (max-width:768px) {
        column-gap: 15px;
    }

    &.img-start {
        justify-content: flex-start;

        @media screen and (max-width:768px) {
            justify-content: center;
        }
    }

    &.img-end {
        justify-content: flex-end;

        @media screen and (max-width:768px) {
            justify-content: center;
        }
    }

    &.space-add {
        column-gap: 75px;

        @media screen and (max-width:768px) {
            column-gap: 15px;
        }
    }

    &.space-add-new {
        column-gap: 75px;

        @media screen and (max-width:900px) {
            column-gap: 40px;
        }

        @media screen and (max-width:768px) {
            column-gap: 9px;
        }
    }



    .user-link-instagram {
        text-align: center;
        margin: 20px 0;

        @media screen and (max-width : 768px) {
            margin: 20px 0 0;
        }
    }

    h3 {
        font-size: 16px;
        font-weight: 800;
        display: block;
        color: var(--dark-black);
        margin: 0;
        padding-top: 10px;
        text-transform: uppercase;

        @media screen and (max-width : 768px) {
            font-size: 10px;
        }

    }
}

.image-cover {
    width: 200px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    flex-direction: column;

    &.images-first {
        @media screen and (max-width : 768px) {
            width: 100px;
        }
    }

    &.images-second {
        width: 250px;

        @media screen and (max-width: 768px) {
            width: 140px;
        }
    }

    &.images-third {
        width: 160px;

        &.opportunity-artist {
            width: 145px;

            @media screen and (max-width : 768px) {
                width: 95px;
            }

            @media screen and (max-width : 399px) {
                width: 65px;
            }
        }

        @media screen and (max-width : 768px) {
            width: 110px;
        }
    }

    &.images-four {
        width: 125px;

        @media screen and (max-width : 768px) {
            width: 60px;
        }

    }

    .user-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }

    &.patricio {
        img {
            object-position: top;
        }
    }

    &.artist-dj {
        max-width: 190px;
        width: 100%;

        @media screen and (max-width : 690px) {
            max-width: 130px;
            width: 100%;
        }

        @media screen and (max-width : 500px) {
            max-width: 85px;
            width: 100%;
        }
    }
}

.overlay {
    position: relative;

    &::before {
        content: "Announcing soon";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000000cb;
        z-index: 999;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 1;


        @media screen and (max-width : 768px) {
            font-size: 9px;
        }
    }
}

// Lineup css end

// weekend guide css start
.sea {
    margin: 40px 0;

    img {
        border-radius: 40px;
        width: 100%;
        height: 500px;
        object-fit: cover;



        @media screen and (max-width : 768px) {
            width: 100%;
            height: 250px;
            border-radius: 15px;

        }

        @media screen and (max-width : 568px) {
            width: 100%;
            height: 150px;
            border-radius: 15px;

        }
    }

    &.bg-images {
        background-image: url('../../public/assets/images/third-3.jpg');
        width: 100%;
        height: 500px;
        background-size: cover;
        background-position: 10% 40%;
        background-repeat: no-repeat;
        border-radius: 40px;

    }
}

.images-list {
    margin: 50px 0;

    @media screen and (max-width : 768px) {
        margin: 20px 0;
    }
}

.items {
    margin-top: 20px;

    h3 {
        text-align: center;

        @media screen and (max-width :768px) {
            text-align: left !important;
        }
    }

    ul {
        column-count: 2;
        margin-top: 20px;
        column-gap: 100px;

        li {
            @extend .text-heading;
            color: var(--link-color);
            padding-bottom: 20px;
        }

        @media screen and (max-width : 992px) {
            column-count: 1;
        }
    }
}

.guide-text {
    padding-left: 20px;
    font-family: 'SpaceMono';

    @media screen and (max-width : 768px) {
        padding-left: 0px;
    }
}

.days {

    ul {
        padding: 0;
        padding-left: 50px;


        @media screen and (max-width : 768px) {
            padding-left: 20px;
        }

        li {

            padding-bottom: 17px;
            @extend .text-heading;
            color: var(--link-color);

            a {
                color: var(--link-color);

                &.click-here {

                    color: var(--theme-color);
                }

            }
        }

    }

    .link {
        list-style: none;
        padding: 0;

        li {
            line-height: 30px;


        }
    }

    &.cashless {
        text-align: center;

        @media screen and (max-width : 768px) {
            text-align: left;
            padding-left: 0 !important;
        }

        ul {
            list-style: none;
        }
    }
}

// weekend guide css start

// partners css start 
.partner-logos {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;


    a {
        width: 170px;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @media screen and (max-width : 499px) {
            width: 100%;

        }
    }

    img {
        width: 100%;

        &.tamarind-bg {
            background-color: var(--white-color);
            padding: 30px 0;
        }

    }
}

// partners css end 

.card-list-sol {
    ul {
        padding: 0;
        text-align: left;
        padding-left: 0px;
        // list-style: none;

        li {
            @extend .text-heading;
            padding-bottom: 10px;

            span {
                font-size: 12px;
            }
        }
    }
}

.modal-bg {
    background-color: var(--theme-color);
    padding: 25px;

    @media screen and (max-width : 768px) {
        padding: 0;
    }

    .modal-header {
        border-bottom: 0px solid;

        .btn-close {
            &:focus {
                box-shadow: none;
                outline: 0;
            }
        }
    }

    .list-form {
        .form-label {
            color: var(--dark-black);
            font-size: 18px;

        }

        .form-select {
            background-image: url('../../public/assets/images/donw-error.png');
        }
    }

    .modal-footer {
        border-top: 0px solid;
        display: flex;
        justify-content: center;
    }

    .form-check {
        .form-check-label {
            padding-left: 10px;
            color: var(--dark-black);
            font-size: 18px;

            @media screen and (max-width:768px) {
                padding-right: 10px;
            }

        }

        .form-check-input {
            background-color: var(--input-color);

            &:checked {
                background-color: var(--dark-black);
                box-shadow: none;
                border-color: transparent;
                outline: 0;
            }
        }


    }

    // .form-check-input[type=radio] {
    //     border-radius: 0px;
    // }

    .form-check-input:checked[type=radio] {
        background-image: url('../../public/assets/images/check.png');
    }

}

.form-select {
    background-image: url('../../public/assets/images/donw-error.png');
    background-size: 17px;
}

.form-control {
    padding: 10px 15px;
    background-color: var(--theme-color);
    border: 0px solid;
    color: var(--input-color);
    border: 1px solid var(--input-color);
    margin-bottom: 10px;
    border-radius: 0;
    font-size: 15px;
    font-weight: 500;

    &::placeholder {
        color: var(--input-color);
        font-size: 17px;
        font-weight: 500;
    }

    &:focus {
        box-shadow: none;
        border-color: transparent;
        // outline: 1px solid var(--dark-black);
        border: 1px solid var(--input-color);
        background-color: var(--theme-color);
        color: var(--input-color);
    }

    &.add-padding {
        padding-left: 70px;
    }
}

.submit-message {
    background-color: var(--theme-color);
    // border: 1px solid #5251514d;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .message {
        p {
            @extend .text-heading;
            color: var(--link-color);
            max-width: 450px;
            width: 100%;
            margin: 0 auto 40px;
            text-align: center;
            background-color: var(--card-color);
            padding: 20px 40px;
            border-radius: 15px;

            @media screen and (max-width:768px) {
                padding: 20px;
            }

            a {
                color: $dark-black ;
            }
        }
    }
}

.privacy-text {
    p {
        color: var(--link-color);
    }

    ul {
        padding: 0 20px;

        li {
            color: var(--link-color);
        }
    }

    a {
        color: var(--link-color);
        text-decoration: underline;

    }
}

.music-cover-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width : 768px) {
        flex-direction: column;
    }

    .beach-club {
        color: var(--dark-black);
        font-size: 30px;

        @media screen and (max-width : 768px) {
            font-size: 20px;
        }

    }
}

.disabled {
    color: #6b686879 !important;
    pointer-events: none;

}

.check-price {
    display: flex;
    gap: 10px;

    @media screen and (min-width : 768px) {
        flex: 1 0 250px;
    }
}

.music-cover {
    @media screen and (max-width:768px) {
        // text-align: center;
        flex-direction: column;

    }

    display: flex;
    // align-items: center;
    justify-content: space-between;
    text-align: left;
    gap: 10px;
    width: calc(100% - 105px);
    // max-width: 450px;
    // width: 100%;

    @media screen and (max-width : 768px) {
        width: calc(100%);
    }

    .day-list-number {
        max-width: 120px;
        width: 100%;

        @media screen and (max-width:992px) {

            max-width: 100%;
            text-align: center;
        }
    }

    h3 {
        font-size: 30px;
        color: var(--dark-black);
        padding-bottom: 30px;
        // max-width: 120px;
        // width: 100%;

        @media screen and (max-width:992px) {
            padding-left: 0;
            font-size: 20px;
            max-width: 100%;
            text-align: center;
        }
    }
}

.checkout-page {
    // padding-right: 110px;

    @media screen and (max-width: 768px) {
        padding-right: 0px;
    }

    .first-checkout {
        // text-align: left;
        max-width: 230px;
        width: 100%;


        @media screen and (max-width: 768px) {
            max-width: 100%;
        }

        ul {
            list-style: none;
            padding: 0;
        }

    }

    .second-checkout {
        text-align: right;
        vertical-align: baseline;
        max-width: 140px;
        width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: flex-end;

        ul {
            list-style: none;
            padding: 0;

            .amount-checkout {
                // width: 200px;
                text-align: center;
                justify-content: flex-end;
                display: flex;
                flex-wrap: wrap;
                gap: 2px;

            }
        }
    }

}

.coupon {
    position: relative;

    &__button {
        position: absolute;
        right: 1px;
        top: -4.4px;


        button {
            // border-radius: 0 !important;
            padding: 8px 12px;
            transform: translateY(1px);
            background-color: transparent !important;
            color: var(--white-color);
            font-size: 16px;

            &:hover {
                color: var(--dark-black);
            }
        }
    }
}

.remove-coupon {
    transform: translateY(13px);
    padding-right: 10px;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    text-decoration: none;
    color: red;
    text-decoration: underline;
}



.extra-links {
    width: 100%;
    margin-bottom: 0;


    ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
    }

    @media screen and (max-width : 992px) {
        padding: 0 0px;
    }


    li {

        padding: 0 10px;

        @media screen and (max-width : 992px) {
            padding: 10px 10px;
        }

        a {
            color: var(--link-color);
            text-decoration: underline;
            text-transform: uppercase;

        }

        span {
            color: var(--link-color);
            text-decoration: underline;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
}

.card-items-list {
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));


    .card-select {
        flex: 1 0 365px;



        @media screen and (max-width : 568px) {
            flex: 1 0 300px;
        }

        .card-items {
            flex: 1;
        }

        &.four-card {
            flex: 1 0 50%;
        }

        &.only-one-card {
            flex: 0 0 50%;
            width: 100%;
            margin: 0 auto;

            @media screen and (max-width:768px) {
                flex: 1 0 100%;
            }
        }
    }
}

.a-link {
    color: var(--link-color);
    text-decoration: underline;
    text-transform: uppercase;
}