.price-new {
    display: flex;
    border: 1px solid var(--link-color);
    align-items: center;
    height: 100% !important;

    @media screen and (max-width : 992px) {
        flex-direction: column;
        align-items: flex-start;
    }

    ul {
        list-style: none;
        flex: 1;
        margin-bottom: 0;
        padding: 10px;
        height: 100% !important;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px 20px;

        @media screen and (max-width : 992px) {
            padding: 30px 10px;
        }

        &:nth-child(1) {
            font-size: 18px;
            color: var(--link-color);
            width: 100%;
            align-items: center;
        }

        &:nth-child(2) {
            border-left: 1px solid var(--link-color);
            border-right: 1px solid var(--link-color);
            font-size: 18px;
            color: var(--link-color);
            width: 100%;

            @media screen and (max-width : 992px) {
                border-left: 0px;
                border-right: 0;
                border-bottom: 1px solid var(--link-color);
                border-top: 1px solid var(--link-color);
                text-align: left;
            }
        }

        &:nth-child(3) {
            align-items: center;
            font-size: 30px;
            color: var(--dark-black);
            text-align: center;

            p {
                margin-bottom: 0;
                font-size: 15px;
                color: #6c757d;
                text-align: center;
            }

            @media screen and (max-width : 992px) {
                width: 100%;
            }
        }


    }
}

.border-b-r {
    border-bottom: 0px !important;
}

.b-t-r {
    border-top: 0px !important;
}

.b-l-r {
    @media screen and (max-width : 768px) {
        border-left: 0px !important
    }
}

.b-r-r {
    @media screen and (min-width :768px) {
        border-right: 0px !important;
    }

    &.m-b-r {
        @media screen and (max-width : 768px) {
            border-right: 0px !important;
        }
    }
}

.b-b-r {
    @media screen and (max-width : 768px) {
        border-bottom: 0px !important;
    }
}

.nav-pills {

    .nav-item {
        width: 50%;
        border: 1px solid var(--link-color);
        padding: 5px;

        &.border-r {
            border-right: 0px !important;

        }

        .nav-link {
            width: 100%;
            color: var(--dark-black);
            border-radius: 0;
            padding: 13px;
        }
    }

}

.nav-pills .nav-link.active {
    background-color: var(--dark-black) !important;
    color: var(--theme-color);
}

.atm-card {
    position: relative;

    .atm-card-image {
        position: absolute;
        top: 6px;
        right: 5px;
    }

}

.size-c {
    font-size: 18px;
    color: var(--link-color);

    @media screen and (max-width : 768px) {
        text-align: center !important;
    }
}

.date-select-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin-bottom: 0;
        color: var(--dark-black);
    }
}

.input-bb {
    border: 0px !important;
    color: var(--link-color) !important;
    margin-bottom: 0 !important;
    font-size: 18px !important;
    background-image: url('../../../public/assets/images/changes.png') !important;
    background-size: 75px !important;
    padding-left: 0 !important;
    cursor: pointer;
}

.select-box {
    text-align: center;
    font-size: 20px !important;
    color: var(--dark-black) !important;

    background-size: 14px !important;

    cursor: pointer;
    height: 100%;

    option {
        font-size: 15px !important;
    }
}

.select-box-2 {
    border-bottom: 1px solid var(--link-color) !important;
    text-align: center;
    font-size: 30px !important;
    cursor: pointer;
    color: var(--dark-black) !important;

    option {
        font-size: 15px !important;
    }
}

.number-passes {
    display: block;
    text-align: right;
    font-size: 18px;
    padding: 30px 0 50px;

    @media screen and (max-width : 992px) {
        text-align: center;
    }
}

.payment-mode {
    font-size: 18px;
    color: var(--link-color);
    margin-bottom: 0;

    @media screen and (max-width : 768px) {
        text-align: center;
        padding: 30px 0;
    }
}

.discount-coupon {
    @media screen and (max-width : 768px) {
        margin-bottom: 0px;
    }
}

.input-new-field {
    width: 100%;
    background-color: var(--theme-color);
    border: 1px solid rgb(255 255 255 / 50%);
    border-radius: 0;
    font-size: 16px;
    padding: 16px;
    color: var(--dark-black);
    outline: 0;

    &::placeholder {
        color: rgba(255 255 255/50%);
    }

    &:active,
    &:focus {
        outline: 1px solid;
    }

    &.input-change {
        padding: 10px;
    }
}


.referral-code-css {
    display: block;
    text-decoration: underline;
    color: var(--link-color);
    cursor: pointer;

    &:hover {
        color: var(--dark-black);
    }
}

.error-t {
    text-align: right;

    @media screen and (max-width : 768px) {
        text-align: right;
        padding-top: 5px;
    }
}



.mum-pass {
    margin: 40px 0;
}

// BEGIN: Checkout Steps


.checkout-step {
    margin-top: 50px;
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    position: relative;

    &.new-add {
        .item {
            @media screen and (max-width :399px) {
                padding: 15px 0;
            }

            select {
                min-width: 160px;
                padding-inline-end: 10px;
            }

        }
    }

    .item {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        border-bottom: 1px solid #32303D;
        align-items: center;

        &.b-b-r {
            border-bottom: 0px;
        }

        >* {
            margin: 0;
        }

        &.items-number {


            @media screen and (max-width: px) {
                max-width: 100%;
            }
        }

        label {
            color: #868494;
        }


        select,
        input {
            background: transparent;
            border: 0;
            outline: 0;
            padding: 0;
            color: white;
            text-align: right;

            @media screen and (max-width : 568px) {
                width: 110px;
            }
        }

        select {
            min-width: 90px;
            padding-inline-end: 10px;


            option {
                background: black;
                padding: 5px;
                color: white;
            }
        }

    }



    .new-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 50%;
        width: 100%;
        margin-left: auto;
        padding: 15px 20px;
        border-bottom: 1px solid #32303D;

        @media screen and (max-width : 768px) {
            max-width: 100%;
        }

        label {
            color: #868494;
        }
    }

    .edit {
        position: absolute;
        top: -30px;
        right: 20px;
    }
}

.checkout-error {
    display: block !important;
    text-align: center;

    h6 {
        font-size: 13px;
    }
}


// END: Checkout Steps